
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 158,
            //             phoneElementId:164,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof PTZ',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIC T6 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP66',
            //                     sort: 3
            //                 }, {
            //                     descName:'-30°C~+60°C' ,
            //                     sort: 4
            //                 }, {
            //                     descName: '≤0.1°',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Protection Level',
            //                     sort: 7
            //                 }, {
            //                     descName: 'Working Temperature',
            //                     sort: 8
            //                 }, {
            //                     descName: 'Position Accuracy',
            //                     sort: 9
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 159,
            //             phoneElementId: 165,
            //             descList: [ {
            //                     descName: 'It is mainly equipped on explosion-proof mobile platforms such as wheeled inspection robots and tracked inspection robots. It is an explosion-proof PTZ, equipped with visible light cameras, fill lights, infrared thermal imagers, laser methane telemetry devices, laser rangefinders and other equipment. It has the ability to observe the environment, identify meter readings, conduct surveys of running, emitting, dripping, and leaking, infrared temperature measurement, methane laser detection, laser rangefinders, and other capabilities.',
            //                     sort: 1
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 160,
            //             phoneElementId: 166,
            //             descList: [
            //                 {
            //                     descName: 'HD Image Output',
            //                     sort: 1
            //                 }, {
            //                     descName: '1080P visible light image and infrared temperature measurement image can be output through ethernet',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId:161,
            //             phoneElementId: 167,
            //             descList: [
            //                 {
            //                     descName: 'Information Feedback',
            //                     sort: 1
            //                 }, {
            //                     descName: 'It has the feedback function of working status and location information of the PTZ',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId: 162,
            //             phoneElementId: 168,
            //             descList: [
            //                 {
            //                     descName: '24/7 Detection',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Equipped with environmental light intensity perception and supplementary light intensity adjustment function, equipped with wipers, it can achieve all-weather patrol detection',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 163,
            //             phoneElementId: 169,
            //             descList: [
            //                 {
            //                     descName: 'High Precision Control',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Using servo motor drive, running smoothly and with high control accuracy',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         }
            //     ],
                elmentList: [],
        }
    },
    created() {
        //         this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
                // 获取当前页面的配置信息
                this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
                    this.elmentList = this.$store.state.pageset.sourceData.elmentList;
                });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
            this.$router.push('/pdfView')
        },

    }
}